ion-modal.bottom-sheet-modal {
  padding: 0 16px;
  padding: 0 24px;
  // can't make the sass variables working without breakign all the fonts => hardcoded lg breakpoint
  @media screen and (max-width: 992px) {
    --border-radius: 4px 4px 0 0;
  }
}

ion-modal.ihz-new-modal {
  // https://docs.google.com/document/d/1fHFvTlOimDSxvE76u2W7qPO2C0vA8u06UKhH_fQ_jc8/edit
  --max-width: 382px;
}
