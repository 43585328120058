/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

$root_class_name: "ihz-app";

@use "libs/shared/ui-global-styles/src/lib/global" with (
  $root_class_name: $root_class_name
);

@import "theme/variables";
@import "theme/overrides/buttons";
@import "theme/overrides/inputs";
@import "theme/overrides/modals";
