.#{$root_class_name} {
  ion-select {
    position: relative;

    &::part(icon) {
      --icon-size: 24px;
      visibility: hidden;
      opacity: 1;
      position: absolute;
      right: 0.875rem;
      width: var(--icon-size);
      height: 100%;

      &::after {
        visibility: visible;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url("data:image/svg+xml;utf-8,%3Csvg%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M11.9987%202C9.34687%202%206.80367%203.05343%204.92855%204.92855C3.05343%206.80367%202%209.34687%202%2011.9987C2%2014.6505%203.05343%2017.1937%204.92855%2019.0688C6.80367%2020.944%209.34687%2021.9974%2011.9987%2021.9974C14.6505%2021.9974%2017.1937%2020.944%2019.0688%2019.0688C20.944%2017.1937%2021.9974%2014.6505%2021.9974%2011.9987C21.9974%209.34687%2020.944%206.80367%2019.0688%204.92855C17.1937%203.05343%2014.6505%202%2011.9987%202ZM6.05992%206.05992C7.63498%204.48486%209.77122%203.6%2011.9987%203.6C14.2262%203.6%2016.3624%204.48486%2017.9375%206.05992C19.5125%207.63498%2020.3974%209.77122%2020.3974%2011.9987C20.3974%2014.2262%2019.5125%2016.3624%2017.9375%2017.9375C16.3624%2019.5125%2014.2262%2020.3974%2011.9987%2020.3974C9.77122%2020.3974%207.63498%2019.5125%206.05992%2017.9375C4.48486%2016.3624%203.6%2014.2262%203.6%2011.9987C3.6%209.77122%204.48486%207.63498%206.05992%206.05992ZM6.83283%2011.1844L11.4324%2015.784L11.9981%2016.3496L12.5638%2015.784L17.1634%2011.1844L16.032%2010.053L11.9981%2014.0869L7.9642%2010.053L6.83283%2011.1844Z%22%20fill%3D%22currentColor%22%2F%3E%3C%2Fsvg%3E");
        background-repeat: no-repeat;
        background-position: center;
        background-size: var(--icon-size) var(--icon-size);
      }
    }
  }
}
