ion-button {
  --border-radius: var(--ion-border-radius);
  font-weight: var(--font-weight-bold);
  height: 48px;
  letter-spacing: 0;

  &.button-outline {
    --border-width: 2px;
    &:hover,
    &:active,
    &:focus {
      opacity: 1;
      &::part(native) {
        background: var(--ion-color-shade);
        color: var(--ion-color-contrast);
      }
    }
  }

  &:hover,
  &:active,
  &:focus {
    // ionic put's --ion-color-tint on hover, we want to have there shade color instead, since we need
    // the buttons to go darken on hover/focus according to designs
    --ion-color-tint: var(--ion-color-shade) !important;
  }
}
