:root {
  //https://ionicframework.com/docs/updating/8-0#light-palette
  --ion-color-primary-highlight: #f1f4fb;
  --ion-font-family: "GT-Walsheim";
  --ion-border-radius: 2px;
  // prettier-ignore
  --tabs-height: calc( var(--tabs-item-height) + 2 * var(--tabs-item-padding-y) + var(--tabs-item-border-size) );

  --font-weight-thin: 400;
  --font-weight-light: 400;
  --font-weight-normal: 400;
  --font-weight-medium: 400;
  --font-weight-semibold: 700;
  --font-weight-bold: 700;
}
